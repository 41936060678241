// ------------------------------------
// DATA
// ------------------------------------
// images are imported as base64 strings
import {cityTourCard, easyCityPass, queerCityPass} from "./images.js"

var data = {
  logos:{
    EasyCityPass: easyCityPass,
    QueerCityPass: queerCityPass,
    CityTourCard: cityTourCard,
  },
  shops: {
    EasyCityPass: [
      {
        de: "Berlin",
        en: "Berlin",
        url: "https://shop.easycitypass.com/EasyCityPass/berlin",
        // url: "https://www.getyourguide.de/berlin-l17/berlin-die-stadt-entdecken-mit-dem-easycitypass-t88868/",
        apps: [
          {
            name:"VBB Bus & Bahn",
            logo: "https://is1-ssl.mzstatic.com/image/thumb/Purple123/v4/02/2d/ed/022dedac-3a72-5f05-6a94-95f9693d3dea/AppIcon-0-1x_U007emarketing-0-0-GLES2_U002c0-512MB-sRGB-0-0-0-85-220-0-0-0-8.png/230x0w.png",
            ios: "https://apps.apple.com/de/app/vbb-bus-bahn/id409357982",
            android: "https://play.google.com/store/apps/details?id=de.hafas.android.vbb",
            onelink: "http://onelink.to/7ceb82"
          },{
            name:"BVG Tickets Berlin",
            logo: "https://popup.easycitypass.com/img/bvg_230x230.png",
            ios: "https://apps.apple.com/de/app/bvg-tickets-berlin/id1232772818",
            android: "https://play.google.com/store/apps/details?id=de.bvg.ticket",
            onelink: "http://onelink.to/h97jwe"
          },
        ]
      },
      {
        de: "Wien",
        en: "Vienna",
        url: "https://shop.easycitypass.com/EasyCityPass/wien",
        apps: [
          {
            name:"WienMobil",
            logo: "https://is2-ssl.mzstatic.com/image/thumb/Purple123/v4/f4/94/d3/f494d381-561e-0a55-49ad-2294483119a5/AppIcon-0-1x_U007emarketing-0-0-GLES2_U002c0-512MB-sRGB-0-0-0-85-220-0-0-0-10.png/230x0w.png",
            ios: "https://apps.apple.com/at/app/wienmobil/id1107918142",
            android: "https://play.google.com/store/apps/details?id=at.wienerlinien.wienmobillab",
            onelink: "http://onelink.to/rh65qg"
          },
          {
            name:"ÖBB",
            logo: "https://is5-ssl.mzstatic.com/image/thumb/Purple126/v4/a6/a8/e4/a6a8e4a4-9676-fe16-dec4-3b6229768feb/AppIcon-1x_U007emarketing-0-4-0-85-220.png/230x0w.png",
            ios: "https://itunes.apple.com/at/app/obb/id1041401604?l=en&mt=8",
            android: "https://play.google.com/store/apps/details?id=at.oebb.ts",
            appgallery: "https://appgallery.huawei.com/#/app/C101935947",
            onelink: "http://onelink.to/zcx4fk"
          }
        ]
      }
    ],
    CityTourCard: [
      {
        de: "München",
        en: "Munich",
        url: "https://shop.easycitypass.com/CityTourCard/Muenchen",
        apps: [
          {
            name:"MVV-App",
            logo: "https://is1-ssl.mzstatic.com/image/thumb/Purple221/v4/cd/2f/28/cd2f2837-8fae-c86f-f6cf-0bd5faa4e366/AppIcon-0-0-1x_U007emarketing-0-7-0-85-220.png/460x0w.webp",
            ios: "https://apps.apple.com/de/app/mvv-app/id388686726",
            android: "https://play.google.com/store/apps/details?id=com.mdv.companion",
            onelink: "http://onelink.to/pzhkwk"
          }
//          ,{
//            name:"MVG Fahrinfo München",
//            logo: "https://is1-ssl.mzstatic.com/image/thumb/Purple113/v4/b3/79/03/b37903ef-e7c6-26f9-b3de-28fe164983ef/AppIcon-0-0-1x_U007emarketing-0-0-0-8-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/230x0w.png",
//            ios: "https://apps.apple.com/de/app/mvg-fahrinfo-m%C3%BCnchen/id343701044",
//            android: "https://play.google.com/store/apps/details?id=de.swm.mvgfahrinfo.muenchen",
//            onelink: "http://onelink.to/5wduyn",
//          }
          ,{
            name:"München Navigator",
            logo: "https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/91/24/dd/9124dd10-c389-faef-b1bf-306f0a797b83/AppIcon-1x_U007emarketing-0-10-0-85-220.png/460x0w.webp",
            ios: "https://apps.apple.com/de/app/m%C3%BCnchen-navigator/id453714981",
            android: "https://play.google.com/store/apps/details?id=de.hafas.android.sbm",
            onelink: "http://onelink.to/wrgbcv",
          }
        ]
      },
    ],
    QueerCityPass: [
      {
        de: "Berlin",
        en: "Berlin",
        url_de: "https://shop.easycitypass.com/QueerCityPass/Berlin?lang=de",
        url_en: "https://shop.easycitypass.com/QueerCityPass/Berlin?lang=en",
        // url: "https://sbahn.berlin/tickets/alle-tickets/touristentickets/queercitypass-berlin/?tabs=tbc-t1",
      },
      {
        de: "Wien",
        en: "Vienna",
        url_de: "https://shop.easycitypass.com/QueerCityPass/Wien?lang=de",
        url_en: "https://shop.easycitypass.com/QueerCityPass/Wien?lang=en",
      },
    ],
  },
  texts: {
    de: {
      // headline per stage
      headline: {
        0: "Welche Stadt möchtest du besuchen?",
        1: "Print Ticket?",
        2: "Partner App herunterladen"
      },
      // text per stage
      text: {
        0: "",
        1: "Ein Print Ticket als PDF auf dem Handy wird von Verkehrsunternehmen leider nicht als gültig angesehen.",
        2: "Du kannst ganz bequem ein mobiles Ticket über unsere lokale Partner App kaufen bzw. herunterladen!"
      },
      ticketButtons:{
        mobile: "Mobiles Ticket",
        mobileComingSoon: "Mobiles Ticket: bald erhältlich",
        print: "Ticket zum Drucken"
      },
      backButton: "Zurück",
      triggerButton: "Ticket kaufen"
    },
    en: {
      // headline per stage
      headline: {
        0: "Which City do you want to visit?",
        1: "Print Ticket?",
        2: "Download Partner App"
      },
      // text per stage
      text: {
        0: "",
        1: "A print ticket as a PDF on the phone is unfortunately not considered valid by public transport companies.",
        2: "You can conveniently buy / download a mobile ticket via our local affiliate app!"
      },
      ticketButtons:{
        mobile: "Mobile Ticket",
        mobileComingSoon: "Mobile Ticket: available soon",
        print: "Print Ticket"
      },
      backButton: "Back",
      triggerButton: "Buy ticket"
    }
  }
}
// generate double entry for munich for easycitypass
data["shops"]["EasyCityPass"].push(data["shops"]["CityTourCard"][0])

export default data
