// ------------------------------------
// REACT IMPORTS
// ------------------------------------
import React from 'react';
import ReactDOM from 'react-dom';
import { ShoppingCartIcon } from '@heroicons/react/outline'
import { TicketIcon } from '@heroicons/react/solid'

// ------------------------------------
// ASSET IMPORTS
// ------------------------------------
import './App.css';
import ios from './ios.png';
import android from './android.png';
import data from "./data.js"

// ------------------------------------
// ELEMENTS
// ------------------------------------
class TriggerButton extends React.Component {
  render() {
    return (
      <div className="trigger-button trigger-button-small tag-manager-popup-open" onClick={this.props.action}>
        {data.texts[this.props.language].triggerButton}
      </div>
    );
  }
}

class MobileTriggerButton extends React.Component {
  render() {
    return (
      <div className="mobile-trigger-button mobile-trigger-button-small tag-manager-popup-open" onClick={this.props.action}>
        <TicketIcon class="ticket-icon" />
        <ShoppingCartIcon class="main-icon" />
      </div>
    );
  }
}

class Funnel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // stage is 0 if its not muenich
      // this is because there are no cities to choose from munich..ther is just munich..
      // so we skip the first step
      stage: this.props.passBrand === "CityTourCard" ? 1 : 0,
      cityId: this.props.passBrand === "CityTourCard" ? 0 : null,
    }
  }

  decreaseStage(){
    var oldStage = this.state.stage
    this.setState({
      stage: oldStage - 1,
      cityId: oldStage > 2 ? null : this.state.cityId
    })
  }

  increaseStage(cityId = null){
    this.setState({
      stage: this.state.stage + 1,
      cityId: cityId != null ? cityId : this.state.cityId
    })
  }

  renderActions(){
    var content = []
    if (this.state.stage === 0) {
      for (var i = 0; i < data.shops[this.props.passBrand].length; i++) {
        let cityId = i
        let city = data.shops[this.props.passBrand][i][this.props.language]
        let url = data.shops[this.props.passBrand][i]["url_" + this.props.language]
        content.push(
          <a key={"tb1"} className="trigger-button expand tag-manager-store-open" target="_blank" rel="noopener noreferrer" href={url}>{city}</a>
        )
      }
    }else if (this.state.stage === 1){
      content.push(
        <a key={"tb1"} className="trigger-button expand tag-manager-store-open" target="_blank" rel="noopener noreferrer" href={data.shops[this.props.passBrand][this.state.cityId].url}>{data.texts[this.props.language].ticketButtons.print}</a>
      )
      if (data.shops[this.props.passBrand][this.state.cityId].apps) {
        content.push(
          <p key={"tb2"} className="trigger-button small expand" onClick={()=>{this.increaseStage()}}>{data.texts[this.props.language].ticketButtons.mobile}</p>
        )
      }
    }else{

      for (var j = 0; j < data.shops[this.props.passBrand][this.state.cityId].apps.length; j++) {
        content.push(
          <div key={j} className="partner-app">
            <img src={data.shops[this.props.passBrand][this.state.cityId].apps[j].logo} className="partner-app-logo" alt="Logo {data.shops[this.props.passBrand][this.state.cityId].apps[j].name}" />
            <p>{data.shops[this.props.passBrand][this.state.cityId].apps[j].name}</p>
            <a target="_blank" rel="noopener noreferrer" href={data.shops[this.props.passBrand][this.state.cityId].apps[j].android} class="tag-manager-app-open">
              <img src={android} className="download-mobile" alt="Android-Play-Store-Logo"/>
            </a>
            <a target="_blank" rel="noopener noreferrer" href={data.shops[this.props.passBrand][this.state.cityId].apps[j].ios} class="tag-manager-app-open">
              <img src={ios} className="download-mobile" alt="Apple-App-Store-Logo"/>
            </a>
          </div>
        )
      }

    }

    return content
  }

  render() {
    var backButton = null
    if (this.state.stage >= 1) {
      backButton = <span className="backFunnel funnelActionButton" onClick={this.decreaseStage.bind(this)}>〱{data.texts[this.props.language].backButton}</span>
    }
    return (
      <div className="funnel-bg">
        <div className="funnel">
          <span className="closeFunnel funnelActionButton" onClick={this.props.closeAction}>×</span>
          {backButton}
          <div className="funnel-logo-container">
            <div className="funnel-logo-container2">
              <img src={data.logos[this.props.passBrand]} className="funnel-logo" alt="{data.logos[this.props.passBrand]}"/>
            </div>
          </div>
          <h2>{data.texts[this.props.language].headline[this.state.stage]}</h2>
          <p>{data.texts[this.props.language].text[this.state.stage]}</p>
          {this.renderActions()}
        </div>
      </div>
    );
  }
}

// Element that generates a button which triggers a popup
class FunnelApp extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      showFunnel: false
    }
  }

  toggleFunnel(){
    this.setState({showFunnel: !this.state.showFunnel})
  }

  render() {
    var funnel = null
    if (this.state.showFunnel) {
      funnel = <Funnel language={this.props.language} closeAction={this.toggleFunnel.bind(this)} passBrand={this.props.passBrand}/>
    }
    var funnelBtn = null
    if (this.props.mobile) {
        funnelBtn = <MobileTriggerButton action={this.toggleFunnel.bind(this)} language={this.props.language} />
    } else {
        funnelBtn = <TriggerButton action={this.toggleFunnel.bind(this)} language={this.props.language} />
    }

    return (
      <div>
        {funnel}
        {funnelBtn}
      </div>
    );
  }
}

// Element that generates a partner overview
class PartnerListApp extends React.Component{
  render() {
    var appElements =[]
    var allApps = []
    // get all partner/apps from a given passBrand
    // this.props.passBrand
    // this.props.passBrandCity
    if (this.props.passBrand != null && this.props.passBrandCity != null) {
      for (var i = 0; i < data.shops[this.props.passBrand].length; i++) {
        if (data.shops[this.props.passBrand][i].apps && this.props.passBrandCity && data.shops[this.props.passBrand][i].en === this.props.passBrandCity) {
          for (var j = 0; j < data.shops[this.props.passBrand][i].apps.length; j++) {
            allApps.push(data.shops[this.props.passBrand][i].apps[j])
          }
        }
      }
  } else if (this.props.passBrand) {
      for (var a = 0; a < data.shops[this.props.passBrand].length; a++) {
        if (data.shops[this.props.passBrand][a].apps) {
          for (var m = 0; m < data.shops[this.props.passBrand][a].apps.length; m++) {
            allApps.push(data.shops[this.props.passBrand][a].apps[m])
          }
        }
      }
    // get all partner/apps
    }else{
      for (var passBrandCity in data.shops) {
        for (var k = 0; k < data.shops[passBrandCity].length; k++) {
          if (data.shops[passBrandCity][k].apps) {
            for (var l = 0; l < data.shops[passBrandCity][k].apps.length; l++) {
              allApps.push(data.shops[passBrandCity][k].apps[l])
            }
          }
        }
      }
    }

    // unique apps
    allApps = [...new Set(allApps)]

    for (var r = 0; r < allApps.length; r++) {
      appElements.push(
        <div class="partner-app-wrapper">
          <p>{allApps[r].name}</p>
          <img src={allApps[r].logo} className="partner-app-logo" alt="partner-app-logo" />
          <div>
          <a key={r} target="_blank" rel="noopener noreferrer" href={allApps[r].ios} className="partner-app">
              <img src={ios} className="download-mobile" alt="Apple-App-Store-Logo"/>
          </a>
            <br/>
          <a key={r} target="_blank" rel="noopener noreferrer" href={allApps[r].android} className="partner-app">
              <img src={android} className="download-mobile" alt="Android-Play-Store-Logo"/>
          </a>
          </div>
        </div>
      )
    }
    return (
      <div className="partner-loop">
       {appElements}
      </div>
    );
  }
}

// Render alls html elebents with the "funnel-element" and "partner-element" class as a button with a popup
document.addEventListener("DOMContentLoaded", function(event) {
  var funnelElements = document.getElementsByClassName("funnel-element")
  for (var p = 0; p < funnelElements.length; p++) {
    // elements except language codes like de_DE or de
    // we are reducing de_DE to de
    let lang = funnelElements[p].getAttribute('data-language')
    if (lang.includes("_")) {
      lang = lang.split("_")[0]
    }
    ReactDOM.render(<FunnelApp language={lang} passBrand={funnelElements[p].getAttribute('data-brand')} mobile={false} />, funnelElements[p]);
  }

  var partnerElements = document.getElementsByClassName("partner-element")
  for (var d = 0; d < partnerElements.length; d++) {
    ReactDOM.render(<PartnerListApp passBrand={partnerElements[d].getAttribute('data-brand')} passBrandCity={partnerElements[d].getAttribute('data-city')}/>, partnerElements[d]);
  }

  var mobileFunnelElements = document.getElementsByClassName("mobile-funnel-element")
  for (var x = 0; x < mobileFunnelElements.length; x++) {
    // elements except language codes like de_DE or de
    // we are reducing de_DE to de
    let lang = mobileFunnelElements[x].getAttribute('data-language')
    if (lang.includes("_")) {
      lang = lang.split("_")[0]
    }
    ReactDOM.render(<FunnelApp language={lang} passBrand={mobileFunnelElements[x].getAttribute('data-brand')} mobile={true} />, mobileFunnelElements[x]);
  }
});
